import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Next up on my conference circuit is the `}<a parentName="p" {...{
        "href": "http://www.americanbanker.com/conferences/cfsi/"
      }}>{`Underbanked Financial Services Forum`}</a>{`, this year in New Orleans. Its one of the few conferences I go to where I have trouble choosing which session to go to - everything is relevant, interesting and timely. And the attendee list is so diverse: "everyone from missionaries to mercenaries" to quote `}<a parentName="p" {...{
        "href": "http://twitter.com/#!/arjanschutte"
      }}>{`Arjan Schutte`}</a>{`'s intro.`}</p>
    <p>{`As I sit here watching demos of the finalists from the `}<a parentName="p" {...{
        "href": "http://blog.corevc.com/?p=379"
      }}>{`Core Underbanked Innovators Challenge`}</a>{`, I am struck by how much the content and format of these demos differs from the traditional demos at a Finnovate, for example. It's the reason why I'm so excited by innovation in this space.`}</p>
    <p>{`These products are not innovative in their technology - they're innovative in their customer centric approach. `}<a parentName="p" {...{
        "href": "http://www.goalmine.com/"
      }}>{`GoalMine`}</a>{` (which is getting my vote, btw) elicited uuuuus and aaaahs for their feature which allows people to move money from their prepaid card to a savings vehicle in 30 seconds. There are probably a dozen companies who `}<em parentName="p">{`COULD`}</em>{` have this functionality but only one (as far as I know) which does. While a savings feature for the LMI segment could seem like a stretch to a tradItional financial services company, GoalMine manages to package it as an obvious extension to GPR. And I bet that has to do with a customer centric approach to innovation.`}</p>
    <p><em parentName="p">{`Note: Stay tuned for more updates from Arlyn from the conference here on the PayPerks blog or follow us on Twitter`}<a parentName="em" {...{
          "href": "http://twitter.com/#!/PayPerksB2B"
        }}>{`@PayPerksB2B`}</a>{`.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      